<template>
  <div id="plans">
    <!-- showcase -->
    <div class="showcase-xxs">
      <h4>Select a billing cycle</h4>
      <!-- tabs for app products -->
      <div v-if="!isPackage" class="jtabs-container jtabs-container-3">
        <button
          class="jbtn-tab"
          v-bind:class="{ active: tabs[0] }"
          @click="changeTab(0)"
        >
          Basic
        </button>
        <button
          class="jbtn-tab"
          v-bind:class="{ active: tabs[1] }"
          @click="changeTab(1)"
        >
          Duo
        </button>
        <button
          class="jbtn-tab"
          v-bind:class="{ active: tabs[2] }"
          @click="changeTab(2)"
        >
          Family
        </button>
      </div>
      <!-- tabs for package products -->
      <div v-else class="jtabs-container jtabs-container-100">
        <button
          class="jbtn-tab"
          v-bind:class="{ active: tabs[3] }"
          @click="changeTab(3)"
        >
          Package 1
        </button>
        <button
          class="jbtn-tab"
          v-bind:class="{ active: tabs[4] }"
          @click="changeTab(4)"
        >
          Package 2
        </button>
      </div>
    </div>
    <div class="wrapper">
      <!-- section -->
      <section
        class="section jpadding section-unfixed"
        style="background: #1193f5"
      >
        <transition
          :duration="{ enter: 1000, leave: 200 }"
          name="custom-classes-transition"
          enter-active-class="animated bounceInLeft"
          leave-active-class="animated fade"
          mode="out-in"
        >
          <!-- basic -->
          <div v-if="tabs[0]" key="1" class="section-prices text-center">
            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>MONTHLY</b></h5>
              <h3>19.99€</h3>
              <div class="jcard-separator"></div>
              <p>
                19.99€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 0%</b></span
                >
              </p>
              <button class="jbtn jbtn-blue" @click="openNextStep(0)">
                Choose
              </button>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Half-Yearly</b></h5>
              <h3>107.99€</h3>
              <div class="jcard-separator"></div>
              <p>
                17.99€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 10%</b></span
                >
              </p>

              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 1 } }"
                @click.native="openNextStep(1)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Yearly</b></h5>
              <h3>179.99€</h3>
              <div class="jcard-separator"></div>
              <p>
                14.99€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 25%</b></span
                >
              </p>

              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 2 } }"
                @click.native="openNextStep(2)"
              >
                Choose
              </router-link>
            </div>
          </div>

          <!-- duo -->
          <div v-else-if="tabs[1]" key="2" class="section-prices text-center">
            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>MONTHLY</b></h5>
              <h3>29.99€</h3>
              <div class="jcard-separator"></div>
              <p>
                29.99€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 0%</b></span
                >
              </p>
              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 3 } }"
                @click.native="openNextStep(3)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Half-Yearly</b></h5>
              <h3>159.99€</h3>
              <div class="jcard-separator"></div>
              <p>
                26.65€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 11%</b></span
                >
              </p>

              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 4 } }"
                @click.native="openNextStep(4)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Yearly</b></h5>
              <h3>269.99€</h3>
              <div class="jcard-separator"></div>
              <p>
                22.49€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 25%</b></span
                >
              </p>

              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 5 } }"
                @click.native="openNextStep(5)"
              >
                Choose
              </router-link>
            </div>
          </div>

          <!-- family -->
          <div v-else-if="tabs[2]" key="3" class="section-prices text-center">
            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>MONTHLY</b></h5>
              <h3>49.99€</h3>
              <div class="jcard-separator"></div>
              <p>
                49.99€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 0%</b></span
                >
              </p>
              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 6 } }"
                @click.native="openNextStep(6)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Half-Yearly</b></h5>
              <h3>267.00€</h3>
              <div class="jcard-separator"></div>
              <p>
                44.50€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 11%</b></span
                >
              </p>

              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 7 } }"
                @click.native="openNextStep(7)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Yearly</b></h5>
              <h3>449.99€</h3>
              <div class="jcard-separator"></div>
              <p>
                37.49€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 25%</b></span
                >
              </p>

              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 8 } }"
                @click.native="openNextStep(8)"
              >
                Choose
              </router-link>
            </div>
          </div>

          <!--------------------------------- package --------------------------------->

          <!-- package 1 -->
          <div v-else-if="tabs[3]" key="4" class="section-prices text-center">
            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Yearly</b></h5>
              <h3>1199€</h3>
              <div class="jcard-separator"></div>
              <p>
                99.99€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 0%</b></span
                >
              </p>
              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 9 } }"
                @click.native="openNextStep(9)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Every 3 years</b></h5>
              <h3>1699€</h3>
              <div class="jcard-separator"></div>
              <p>
                47.20€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 53%</b></span
                >
              </p>

              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 10 } }"
                @click.native="openNextStep(10)"
              >
                Choose
              </router-link>
            </div>
          </div>

          <!-- package 2 -->
          <div
            v-else-if="tabs[4]"
            key="5"
            class="section-prices section-prices-2 text-center"
          >
            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Yearly</b></h5>
              <h3>1299€</h3>
              <div class="jcard-separator"></div>
              <p>
                108.25€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 0%</b></span
                >
              </p>
              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 11 } }"
                @click.native="openNextStep(11)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <h5><b>Every 3 years</b></h5>
              <h3>1799€</h3>
              <div class="jcard-separator"></div>
              <p>
                49.97€ / month<br />
                <span class="text-blue"
                  ><b><i class="fa fa-percentage"></i> save 53%</b></span
                >
              </p>

              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'CreateAccount', query: { buy: 12 } }"
                @click.native="openNextStep(12)"
              >
                Choose
              </router-link>
            </div>
          </div>
        </transition>
        <div class="sub-link-container">
          <router-link :to="{ name: 'Plans', query: { t: isPackage ? 1 : 0 } }"
            ><i class="fa fa-arrow-left"></i> Back to plans</router-link
          >
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import C from "@/constants";
import PLANS from "@/plans";

export default {
  name: "BillingCycles",
  data() {
    return {
      buyProduct: 0,
      isPackage: false,
      activeTab: 0,
      tabs: [false, false, false, false, false],
      subStatusList: PLANS.STATUS_INDEX,
      subTypeList: PLANS.TYPE_INDEX,

      //display
      showTabs: true,

      //userId: firebase.auth().currentUser.uid,
      subscriptionsList: [PLANS.DEFAULT_SUB],
    };
  },
  methods: {
    // startContrat(type) {
    //   this.$store.commit("startAddContrat");
    // },
    openNextStep(plan) {
      //set plan id
      this.buy.plan = plan;
      // get PLAN
      var PLAN = PLANS.ALL[plan];
      // set plan type
      this.buy.type = PLAN.TYPE;
      //this.$store.commit("setBuy", this.buy);
      if (PLAN.TYPE == 1) {
        this.isPackage = true;
        console.log("buy: is package");
      }
      this.$store.commit("setIsPackage", this.isPackage);
      this.$store.commit("setMode", C.MODE.BUY);
      console.log("Plan selected: " + this.buy.plan + ". Go to account.");
      //   this.$store.commit("setBuyStep", 1);

      this.$router.push({
        name: "CreateAccount",
        query: { buy: plan },
      });
    },
    openSubscription(index) {
      this.$store.commit("setSubscription", this.subscriptionsList[index]);
      this.$router.push({
        name: "Subscription",
        params: { id: this.subscriptionsList[index].id },
      });
    },
    changeTab(clicked) {
      if (this.showTabs) {
        this.$set(this.tabs, this.activeTab, false);
        this.$set(this.tabs, clicked, true);
        this.activeTab = clicked;
        //todo check and set isPackage
      }
    },
    getFormatDate(date) {
      console.log(date);
    },
    loadBillingCycles(product) {
      if (C.isInt(product)) {
        //invalid product
        if (!product > 4) {
          console.log("Input is not a valid product.");
        } else {
          this.buyProduct = product;
          this.activeTab = product;
          this.changeTab(product);

          //isPackage alt via state
          if (this.buyProduct > 2) {
            this.isPackage = true;
          }
        }
      } else {
        console.log("Input is not valid.");
      }
    },
    init() {
      var loaded = false;

      //called with query
      if (!this.$route.query.p) {
        console.log("No product selected via query.");
        //load store here
      } else {
        this.loadBillingCycles(this.$route.query.p);
        loaded = true;
      }

      //called with params
      if (!this.$route.params.product) {
        console.log(
          "No product selected via params. Default cycle (0) was loaded."
        );
      } else {
        this.loadBillingCycles(this.$route.params.product);
        loaded = true;
      }

      if (!loaded) {
        this.loadBillingCycles(this.buyProduct);
      }
    },
  },
  computed: {
    buy: {
      get() {
        return this.$store.getters.getBuy;
      },
    },
  },
  mounted() {
    this.init();
    console.log("store product: " + this.buy.product);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/plans.scss";
</style>
